import React from 'react'
import { Link } from 'react-router-dom'

import BitcoinIraImage from '~assets/images/bitcoinira.svg'
import DigitalTrustImage from '~assets/images/digitaltrust.svg'
import MsdiraLogo from '~assets/images/msdira.svg'
import PoweredByLogo from '~assets/images/powered-by.svg'
import { styled } from '~styles'

const Root = styled(Link)`
  display: inline-block;
  padding: 6px 6px 6px ${({ theme }) => theme.gap}px;
`

const getLogo = (theme?: string, showPoweredBy?: boolean) => {
  switch (theme) {
    case 'msdira':
      return (
        <div className="flex items-center">
          <MsdiraLogo className="h-12" />
          {showPoweredBy && (
            <>
              <div className="ml-2 mr-4 h-10 border-r border-gray-300" />
              <PoweredByLogo className="h-5 lg:h-7" />
            </>
          )}
        </div>
      )
    case 'bira':
      return <BitcoinIraImage className="h-12" />
    default:
      return <DigitalTrustImage className="h-10" />
  }
}

type BrandLinkProps = {
  className?: string
  showPoweredBy?: boolean
  theme?: string
}

const BrandLink = ({ className, showPoweredBy, theme }: BrandLinkProps) => {
  return (
    <Root className={className} to="/">
      {getLogo(theme, showPoweredBy)}
    </Root>
  )
}

export default BrandLink
