import { keyframes } from '@emotion/react'

export const slideIn = keyframes`
	0% {
		opacity: 0;
    transform: translateY(-10px);
	}
  100% {
		opacity: 1;
    transform: translateY(0);
	}
`
