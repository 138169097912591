import { CSSObject, css } from '@emotion/react'
import styled from '@emotion/styled'
import { em, transparentize } from 'polished'
import { forwardRef } from 'react'

import { CustomTheme } from '~styles'
import { control } from '~styles/mixins'

type RadioColor = 'default' | 'danger'

type RadioVariant = keyof typeof radioVariants

export interface RadioProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'disabled' | 'required' | 'type'
  > {
  id: string
  isDisabled?: boolean
  isRequired?: boolean
  color?: RadioColor
  variant?: RadioVariant
}

const radioColors = (theme: CustomTheme): Record<RadioColor, CSSObject> => ({
  default: {
    borderColor: theme.grayLighter,
  },
  danger: {
    borderColor: theme.danger,
  },
})

const radioVariants = {
  default: {
    display: 'inline-block',
  },
  block: {
    display: 'block',
    width: '100%',
  },
}

const disabled = (theme: CustomTheme) => css`
  background-color: ${theme.whiteBis};
  color: ${theme.textLightColor};
  cursor: not-allowed;
`

const Root = styled.div<{ variant?: RadioVariant }>`
  position: relative;
  ${({ variant = 'default' }) => radioVariants[variant]};
`

const Label = styled.label<{ color?: RadioColor; variant?: RadioVariant }>`
  ${control}
  background-color: ${({ theme }) => theme.white};
  display: inline-block;
  cursor: pointer;
  font-size: ${em(13)};
  min-width: 100px;
  padding: 12px 15px 0 35px;
  white-space: nowrap;
  ${({ color = 'default', theme }) => radioColors(theme)[color]};
  ${({ variant = 'default' }) => radioVariants[variant]};

  [type='radio']:where(:focus, :checked) + & {
    background-color: ${({ theme }) => transparentize(0.97, theme.blue)};
    border-color: ${({ theme }) => theme.blue};
  }

  [type='radio']:where(:disabled) + &,
  fieldset[disabled] & {
    ${({ theme }) => disabled(theme)}
  }
`

const InnerInput = styled.input`
  position: absolute;
  top: calc(50% - ${em(8)});
  left: ${em(15)};
`

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      className,
      color,
      id,
      isDisabled,
      isRequired,
      variant,
      ...props
    },
    ref,
  ) => {
    return (
      <Root className={className} variant={variant}>
        <InnerInput
          {...props}
          disabled={isDisabled}
          required={isRequired}
          id={id}
          type="radio"
          ref={ref}
        />
        <Label color={color} htmlFor={id} variant={variant}>
          {children}
        </Label>
      </Root>
    )
  },
)
Radio.displayName = 'Radio'

export { Radio }
