/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

type PredicateFunction = (entry: { key: string; value: any }) => boolean

export const pickWhere =
  <T extends Record<string, any>>(predicate: PredicateFunction) =>
  (obj: T): T =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      if (predicate({ key, value })) {
        acc[key as keyof T] = value
      }
      return acc
    }, {} as T)

export const pickNonNullValues = pickWhere(({ value }) => value !== null)

export const lowercaseKeys = (obj: Record<string, any>) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]))
