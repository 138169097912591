import { em } from 'polished'
import React from 'react'

import PoweredByDT from './powered-by-dt.svg'
import { styled } from '~styles'

const Text = styled.div`
  color: ${({ theme }) => theme.textLightColor};
  font-size: ${em(12)};
  margin-bottom: 20px;
  text-align: center;
`

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  theme?: string
}

const Footer: React.FC<Props> = ({ theme, ...rest }) => {
  return (
    <footer {...rest}>
      {theme === 'msdira' ? (
        <div className="flex flex-col items-center space-y-4">
          <PoweredByDT />
          <Text>
            &copy;{new Date().getFullYear()} My Self-Directed IRA. All Rights
            Reserved.&nbsp;
            <a
              className="underline"
              href="https://myselfdirectedira.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            <span className="mx-1">|</span>
            <a
              className="underline"
              href="https://myselfdirectedira.com/terms-of-use"
              target="_blank"
            >
              Terms of Use
            </a>
          </Text>
        </div>
      ) : (
        <Text>
          &copy;{new Date().getFullYear()} All Rights Reserved | Digital Trust
          |&nbsp;
          <a href="https://digitaltrust.com/terms-conditions" target="_blank">
            Terms & Conditions
          </a>
          ,&nbsp;
          <a
            href="https://digitaltrust.com/self-directed-account-forms"
            target="_blank"
          >
            Disclosures
          </a>
          ,&nbsp;
          <a href="https://digitaltrust.com/privacy-policy" target="_blank">
            Privacy Policy
          </a>
          &nbsp;and&nbsp;
          <a href="https://digitaltrust.com/web-accessibility" target="_blank">
            Accessibility Statement
          </a>
        </Text>
      )}
    </footer>
  )
}

export default Footer
