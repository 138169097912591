import React from 'react'
import { useFormContext } from 'react-hook-form'

import BasePasswordStrength from '~components/password-strength'
import { useFieldContext } from './field'

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

const PasswordStrength: React.FC<Props> = props => {
  const { watch } = useFormContext()
  const [{ name }] = useFieldContext()
  const value = watch(name) as string

  return <BasePasswordStrength password={value} {...props} />
}

export default PasswordStrength
