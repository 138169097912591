import React, { forwardRef } from 'react'
import { get, useFormContext } from 'react-hook-form'

import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from '~components/inputs/checkbox'
import { mergeRefs } from '~utils/react'
import { useFieldContext } from './field'

export type CheckboxProps = Omit<
  BaseCheckboxProps,
  'isDisabled' | 'isRequired' | 'name'
>

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Radio(
  props,
  ref,
) {
  const [{ isDisabled, isRequired, name }] = useFieldContext()
  const {
    formState: { errors },
    register,
  } = useFormContext()

  const isInvalid = Boolean(get(errors, name))

  const { ref: inputRef, ...rest } = register(name, {
    required: isRequired && 'Input is required',
  })

  return (
    <BaseCheckbox
      {...props}
      {...rest}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isRequired={isRequired}
      ref={mergeRefs(ref, inputRef)}
    />
  )
})
Checkbox.displayName = 'Checkbox'

export default Checkbox
