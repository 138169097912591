import { em, rem, transparentize } from 'polished'
import {
  Menu,
  MenuButton as BaseMenuButton,
  MenuItem,
  MenuItems as BaseMenuItems,
  MenuButtonProps,
  MenuPopover,
} from '@reach/menu-button'
import { positionMatchWidth } from '@reach/popover'
import styles from '@reach/menu-button/styles.css'
import useStyles from 'isomorphic-style-loader/useStyles'
import React from 'react'
import { FiLogOut } from 'react-icons/fi'

import { WithCustomTheme, css, styled } from '~styles'
import { slideIn } from '~styles/animations'

export interface Props extends Omit<MenuButtonProps, 'children'> {
  displayName?: string
  onSignOut: () => void
}

const MenuButton = styled(BaseMenuButton)`
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 0;
  border-width: 0;
  cursor: pointer;
  display: flex;
  font-size: ${em(11)};
  height: 50px;
  outline: none;
  padding: 0 15px;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.whiteTer};
  }
`

const menuItem = ({ theme }: WithCustomTheme) => css`
  align-items: center;
  display: flex;
  color: ${theme.grayDarker};
  font-size: ${em(14)};
  font-weight: 600;
  line-height: 1;
  padding: 10px 15px;

  &[data-selected] {
    background: ${theme.whiteBis};
    color: ${theme.grayDarker};
  }
`

const MenuItems = styled(BaseMenuItems)`
  animation: ${slideIn} 0.2s ease;
  background: ${({ theme }) => theme.white};
  box-shadow: 0 4px 7px 0 ${({ theme }) => transparentize(0.85, theme.black)};
  border-width: 0;
  font-size: 1em;
  padding: ${em(10)} 0;

  [data-reach-menu-item] {
    ${menuItem}
  }
`

const Arrow = styled.div`
  &::after {
    color: ${({ theme }) => theme.grayLighter};
    content: '▾';
    font-size: ${rem(16)};
  }
`
Arrow.defaultProps = {
  'aria-hidden': true,
}

const Prefix = styled.span`
  align-items: center;
  border-radius: 25%;
  display: inline-flex;
  height: ${rem(24)};
  justify-content: center;
  line-height: 1;
  margin-right: ${rem(10)};
  width: ${rem(24)};

  .active & {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.primary};
  }
`

const Postfix = styled.span`
  transform: rotate(-90deg);

  &::after {
    color: ${({ theme }) => transparentize(0.5, theme.white)};
    content: '▾';
    font-size: ${rem(16)};
    line-height: 1;
  }
`
Postfix.defaultProps = {
  'aria-hidden': true,
}

const AccountMenu: React.FC<Props> = ({ displayName, onSignOut, ...props }) => {
  useStyles(styles)

  return (
    <Menu>
      <MenuButton as="button" {...props}>
        <div css={{ flex: 1 }}>
          <div css={{ fontWeight: 600 }}>My Account</div>
          {displayName && <div css={{ fontWeight: 700 }}>{displayName}</div>}
        </div>
        <Arrow />
      </MenuButton>
      <MenuPopover css={{ zIndex: 20 }} position={positionMatchWidth}>
        <MenuItems as="div">
          <MenuItem onSelect={onSignOut}>
            <Prefix children={<FiLogOut />} />
            <span css={{ flex: 1 }}>Sign Out</span>
            <Postfix />
          </MenuItem>
        </MenuItems>
      </MenuPopover>
    </Menu>
  )
}

export default AccountMenu
