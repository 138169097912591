/* eslint-disable no-param-reassign, @typescript-eslint/no-unsafe-assignment, unicorn/no-null */
import { MutableRefObject } from 'react'

export const mergeRefs =
  <T>(...refs: Array<MutableRefObject<T> | Function | null>) =>
  (value: any) => {
    refs.filter(Boolean).forEach(ref => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ref.current = value
      }
    })
  }
