import { rem } from 'polished'
import React, { forwardRef } from 'react'

import { styled } from '~styles'
import { useFieldContext } from './field'

export interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode
}

const Root = styled.label`
  display: inline-block;
  font-size: ${rem(14)};
  font-weight: 600;
  margin-bottom: 5px;
`

const Required = styled.span`
  &::after {
    color: ${({ theme }) => theme.textLightColor};
    content: '*';
  }
`

const Label = forwardRef<HTMLLabelElement, Props>(function Label(
  { children, ...props },
  ref,
) {
  const [{ controlId, isRequired }] = useFieldContext()

  return (
    <Root htmlFor={controlId} ref={ref} {...props}>
      {children}
      {isRequired && <Required />}
    </Root>
  )
})
Label.displayName = 'Label'

export default Label
