import { rem, transparentize } from 'polished'
import React from 'react'
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom'

import {
  ActivityIcon,
  CoinIcon,
  DocIcon,
  EyeIcon,
  UploadIcon,
} from '~assets/icons'
import { WithCustomTheme, css, styled } from '~styles'
import { cx } from '~utils/classname'

type ThemedNavLinkProps = React.ForwardRefExoticComponent<
  NavLinkProps &
    React.RefAttributes<HTMLAnchorElement> & { variant: NavMenuVariant }
>

export interface Props extends React.HTMLAttributes<HTMLElement> {
  isExpanded?: boolean
  variant?: NavMenuVariant
}

const variants = {
  dark: ({ theme }: WithCustomTheme) => css`
    color: ${transparentize(0.3, theme.white)};

    &.active {
      color: ${theme.white};
    }

    [data-postfix] {
      color: ${transparentize(0.7, theme.white)};
    }
  `,
  light: ({ theme }: WithCustomTheme) => css`
    color: ${transparentize(0.3, theme.grayDarker)};

    &.active {
      color: ${theme.grayDarker};
    }

    [data-postfix] {
      color: ${transparentize(0.7, theme.grayDarker)};
    }
  `,
}
type NavMenuVariant = keyof typeof variants

const NavLink = styled<ThemedNavLinkProps>(BaseNavLink)`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: inherit;
  font-weight: 600;
  line-height: 1;
  padding: 10px 15px;
  user-select: none;
  ${({ variant }) => variants[variant]}

  &:hover {
    background-color: ${({ theme, variant }) =>
      variant === 'dark'
        ? transparentize(0.9, theme.white)
        : transparentize(0.9, theme.black)};
    color: ${({ theme, variant }) =>
      variant === 'dark' ? theme.white : theme.grayDarker};
  }

  &[data-disabled] {
    background-color: inherit;
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
`

const Prefix = styled.span`
  align-items: center;
  color: inherit;
  border-radius: 25%;
  display: inline-flex;
  height: ${rem(36)};
  justify-content: center;
  line-height: 1;
  width: ${rem(36)};

  &:not(:last-child) {
    margin-right: 10px;
  }

  .active & {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.primary};
  }
`

const Postfix = styled.span`
  transform: rotate(-90deg);

  &::after {
    color: inherit;
    content: '▾';
    font-size: ${rem(16)};
    line-height: 1;
  }
`
Postfix.defaultProps = {
  'aria-hidden': true,
}

const NavMenu: React.FC<Props> = ({
  isExpanded = true,
  variant = 'dark',
  ...props
}) => (
  <nav {...props}>
    <ul>
      <li>
        <NavLink end title="Account Summary" to="/" variant={variant}>
          <Prefix children={<ActivityIcon css={{ fontSize: rem(22) }} />} />
          <span
            className={cx({ 'flex-grow': isExpanded, 'sr-only': !isExpanded })}
          >
            Account Summary
          </span>
          <Postfix
            aria-hidden
            className={cx({ hidden: !isExpanded })}
            data-postfix
          />
        </NavLink>
      </li>
      <li>
        <NavLink data-disabled title="Account Details" to="#" variant={variant}>
          <Prefix children={<EyeIcon css={{ fontSize: rem(22) }} />} />
          <span
            className={cx({ 'flex-grow': isExpanded, 'sr-only': !isExpanded })}
          >
            Account Details
          </span>
          <Postfix
            aria-hidden
            className={cx({ hidden: !isExpanded })}
            data-postfix
          />
        </NavLink>
      </li>
      <li>
        <NavLink title="Statements" to="statements" variant={variant}>
          <Prefix children={<DocIcon css={{ fontSize: rem(22) }} />} />
          {isExpanded && (
            <>
              <span css={{ flex: 1 }}>Statements</span>
              <Postfix data-postfix />
            </>
          )}
        </NavLink>
      </li>
      <li>
        <NavLink data-disabled title="Billing" to="#" variant={variant}>
          <Prefix children={<CoinIcon css={{ fontSize: rem(22) }} />} />
          <span
            className={cx({ 'flex-grow': isExpanded, 'sr-only': !isExpanded })}
          >
            Billing
          </span>
          <Postfix
            aria-hidden
            className={cx({ hidden: !isExpanded })}
            data-postfix
          />
        </NavLink>
      </li>
      <li>
        <NavLink data-disabled title="Document Upload" to="#" variant={variant}>
          <Prefix children={<UploadIcon css={{ fontSize: rem(22) }} />} />
          <span
            className={cx({ 'flex-grow': isExpanded, 'sr-only': !isExpanded })}
          >
            Document Upload
          </span>
          <Postfix
            aria-hidden
            className={cx({ hidden: !isExpanded })}
            data-postfix
          />
        </NavLink>
      </li>
    </ul>
  </nav>
)

export { default as NavMenuFooter } from './footer'
export { NavMenu }
