import { styled } from '~styles'
import { unselectable } from '~styles/mixins'

export const LinkButton = styled.button`
  ${unselectable}
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.linkColor};
  cursor: pointer;
  display: inline-block;
`
