import { em, rem, transparentize } from 'polished'
import React from 'react'

import { css, styled } from '~styles'

export interface Props
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  isActive?: boolean
  size?: number
  width?: number
}

interface LineProps {
  isActive?: boolean
  size: number
  width: number
}

const Root = styled.button`
  background-color: transparent;
  border-width: 0;
  color: ${({ theme }) => theme.grayDarker};
  display: block;
  font-size: ${rem(16)};
  height: ${em(40)};
  outline: none;
  padding: 0;
  position: relative;
  width: ${em(40)};

  &:hover {
    background: ${({ theme }) => transparentize(0.9, theme.white)};
  }
`
Root.defaultProps = { 'aria-label': 'menu' }

const Line = styled.div<LineProps>`
  background-color: currentColor;
  height: ${({ width }) => `${width}px`};
  left: calc(50% - ${({ size }) => em(Math.floor(size / 2))});
  outline: none;
  position: absolute;
  transform-origin: center;
  transition-duration: ${({ theme }) => theme.duration};
  transition-property: background-color, opacity, transform;
  transition-timing-function: ${({ theme }) => theme.timingFunction};
  width: ${({ size }) => em(size)};

  &:nth-of-type(1) {
    top: calc(50% - ${({ width }) => 5 + width}px);

    ${({ isActive }) =>
      isActive &&
      css`
        transform: translateY(5px) rotate(45deg);
      `}
  }

  &:nth-of-type(2) {
    top: calc(50% - ${({ width }) => width}px);

    ${({ isActive }) =>
      isActive &&
      css`
        opacity: 0;
      `}
  }

  &:nth-of-type(3) {
    top: calc(50% + ${({ width }) => 5 - width}px);

    ${({ isActive }) =>
      isActive &&
      css`
        transform: translateY(-5px) rotate(-45deg);
      `}
  }
`
Line.defaultProps = { 'aria-hidden': true }

const Toggle: React.FC<Props> = ({
  isActive,
  size = 16,
  width = 1,
  ...props
}) => {
  const lineProps: LineProps = {
    isActive,
    size,
    width,
  }

  return (
    <Root aria-expanded={isActive} {...props}>
      <Line {...lineProps} />
      <Line {...lineProps} />
      <Line {...lineProps} />
    </Root>
  )
}

export default Toggle
