import React, { ChangeEventHandler, forwardRef } from 'react'
import { ValidationRule, get, useFormContext } from 'react-hook-form'
import { useUIDSeed } from 'react-uid'

import BaseInput, { InputProps } from '~components/input'
import { mergeRefs } from '~utils/react'
import { useFieldContext } from './field'

interface Props extends Omit<InputProps, 'isDisabled' | 'isRequired' | 'name'> {
  rules?: ValidationRule[]
}

export const useInputField = ({
  id,
  onChange,
  rules,
}: {
  id?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  rules?: ValidationRule[]
}) => {
  const [{ controlId, isDisabled, isRequired, name }, dispatch] =
    useFieldContext()

  const {
    formState: { errors },
    register,
  } = useFormContext()

  const seed = useUIDSeed()

  React.useEffect(
    () =>
      dispatch({
        type: 'SET_CONTROLID',
        controlId: id || seed(name),
      }),
    [dispatch, id, name, seed],
  )

  const isInvalid = Boolean(get(errors, name))

  const { ref: inputRef, ...registerProps } = register(name, {
    required: isRequired && 'Input is required',
    ...rules,
  })

  const handleChange: ChangeEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      onChange?.(e)
      void registerProps.onChange?.(e)
    },
    [onChange, registerProps],
  )

  return {
    controlId,
    inputProps: {
      ...registerProps,
      'aria-invalid': isInvalid ? 'true' : 'false',
      id: controlId,
      onChange: handleChange,
    },
    inputRef,
    isDisabled,
    isInvalid,
    isRequired,
  } as const
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  { id, onChange, rules, variant, ...props },
  ref,
) {
  const { isInvalid, isDisabled, isRequired, inputProps, inputRef } =
    useInputField({ id, onChange })

  return (
    <BaseInput
      {...inputProps}
      isDisabled={isDisabled}
      isRequired={isRequired}
      ref={mergeRefs(ref, inputRef)}
      type="text"
      variant={isInvalid ? 'danger' : variant}
      {...props}
    />
  )
})
Input.displayName = 'Input'

export default Input
