import React from 'react'

import { loadConfig } from '~config.client'
import { mq, styled } from '~styles'
import BrandLink from './brand-link'
import Contact from './contact'

const Root = styled.header`
  border-bottom: solid 1px ${({ theme }) => theme.whiteTer};
  display: flex;
  margin: auto;
  max-width: 1000px;
  position: relative;

  ${mq('md')} {
    padding: 5px 0;
  }
`

const UnauthenticatedHeader: React.FC = props => (
  <Root {...props}>
    <div css={{ alignItems: 'center', display: 'flex', flex: 1 }}>
      <div css={{ flex: 'none' }}>
        <BrandLink theme={loadConfig().siteTheme} />
      </div>
      <Contact />
    </div>
  </Root>
)

export default UnauthenticatedHeader
