import { darken, em, transparentize } from 'polished'
import React, { forwardRef } from 'react'

import { useTimer } from '~hooks/use-timer'
import {
  CustomTheme,
  Variant,
  WithCustomTheme,
  css,
  styled,
  variantColors,
} from '~styles'
import { control, spinner } from '~styles/mixins'

export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'disabled' | 'required'
  > {
  isDisabled?: boolean
  isLoading?: boolean
  isReadOnly?: boolean
  isRequired?: boolean
  type?: 'email' | 'password' | 'tel' | 'text'
  variant?: Variant
}

interface RootProps {
  isLoading?: boolean
  variant?: Variant
}

const loading = ({
  isLoading = false,
  theme,
  variant,
}: WithCustomTheme<RootProps>) =>
  isLoading &&
  css`
    pointer-events: none;
    color: transparent;
    &::after {
      ${spinner(variant ? theme[variantColors[variant]] : undefined)({
        theme,
      })}
      position: absolute;
      right: ${em(10)};
      top: calc(50% - ${em(8)});
    }
  `

const Root = styled.div<RootProps>`
  ${loading}
  position: relative;
`

const basic = (theme: CustomTheme) => css`
  border-color: ${theme.grayLighter};

  &:hover {
    border-color: ${theme.grayLight};
  }

  &:focus,
  &:active {
    border-color: ${theme.linkColor};
  }
`

const variant = ({ theme, variant }: WithCustomTheme<RootProps>) => {
  if (!variant) return basic(theme)

  const color = theme[variantColors[variant]]

  return css`
    border-color: ${color};

    &:hover {
      border-color: ${darken(0.025, color)};
    }
  `
}

const disabled = ({ theme }: WithCustomTheme<RootProps>) => css`
  &[disabled],
  fieldset[disabled] & {
    background-color: ${theme.whiteBis};
    box-shadow: none;
    color: ${theme.textColor};

    &::placeholder {
      color: ${transparentize(0.7, theme.textLightColor)};
    }
  }
`

const readOnly = ({ theme }: WithCustomTheme<RootProps>) => css`
  &:read-only {
    background-color: ${theme.whiteBis};
    box-shadow: none;
    color: ${theme.textColor};

    &::placeholder {
      color: ${transparentize(0.7, theme.textLightColor)};
    }
  }
`

const InnerInput = styled.input<{ variant?: Variant }>`
  ${control}
  background-color: ${({ theme }) => theme.white};
  border-radius: 0;
  border-width: 1px;
  padding: 0 ${em(32)} 0 ${em(15)};
  width: 100%;
  ${variant}

  &::placeholder {
    color: ${({ theme }) => transparentize(0.7, theme.grayDarker)};
  }

  ${disabled}
  ${readOnly}
`

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoComplete = 'off',
      className,
      isDisabled,
      isLoading = false,
      isReadOnly,
      isRequired,
      onChange,
      variant,
      ...props
    },
    ref,
  ) => {
    const isLoaderVisible = useTimer(isLoading, 200)
    const ariaLabel = isLoading ? { 'aria-label': 'loading' } : {}

    return (
      <Root className={className} isLoading={isLoaderVisible} variant={variant}>
        <InnerInput
          autoComplete={autoComplete}
          readOnly={isReadOnly}
          disabled={isDisabled}
          onChange={event => onChange?.(event)}
          ref={ref}
          required={isRequired}
          variant={variant}
          {...props}
          {...ariaLabel}
        />
      </Root>
    )
  },
)
Input.displayName = 'Input'

export default Input
