import styled from '@emotion/styled'
import { rem } from 'polished'
import { FC, useMemo } from 'react'

interface CheckboxIconProps {
  isChecked?: boolean
}

const Root = styled.svg<{ isChecked?: boolean }>`
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
  opacity: 1;
  transition-duration: 200ms;
  width: ${rem(12)};
`

const CheckboxIcon: FC<CheckboxIconProps> = props => {
  const { isChecked, ...rest } = props

  const iconStyles = useMemo(
    () => ({
      opacity: isChecked ? 1 : 0,
      transform: isChecked ? 'scale(1)' : 'scale(0.95)',
    }),
    [isChecked],
  )

  return (
    <Root css={iconStyles} viewBox="0 0 12 10" {...rest}>
      <polyline points="1.5 6 4.5 9 10.5 1" />
    </Root>
  )
}

export { CheckboxIcon }
