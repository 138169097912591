import { darken, em } from 'polished'
import React from 'react'

import { useTimer } from '~hooks/use-timer'
import {
  CustomTheme,
  Variant,
  WithCustomTheme,
  css,
  styled,
  variantColorContrasts,
  variantColors,
} from '~styles'
import { arrow, control, spinner, rotate, unselectable } from '~styles/mixins'

export interface Props
  extends Omit<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    'disabled' | 'multiple' | 'required'
  > {
  delay?: number
  isDisabled?: boolean
  isLoading?: boolean
  isMultiple?: boolean
  isRequired?: boolean
  variant?: Variant
}

interface BaseSelectProps {
  variant?: Variant
}

interface RootProps {
  isDisabled?: boolean
  isLoading?: boolean
  isMultiple?: boolean
  variant?: Variant
}

const single = (color: string, borderColor: string) => css`
  &::after {
    ${arrow(color)}
    ${rotate(45)}
    position: absolute;
    right: 13px;
    top: calc(50% - 6px);
  }

  &:hover::after {
    border-color: ${borderColor};
  }
`

const loading = ({
  isDisabled,
  isLoading,
  isMultiple,
  theme,
  variant,
}: WithCustomTheme<RootProps>) => {
  if (isDisabled || isMultiple) return

  const color = variant
    ? theme[variantColorContrasts[variant]]
    : theme.grayLight

  return isLoading
    ? css`
        &::after {
          ${spinner(color)({ theme })}
          position: absolute;
          right: 10px;
          top: calc(50% - 8px);
        }
      `
    : single(color, theme.grayDarker)
}

const Root = styled.div<RootProps>`
  position: relative;
  ${loading}
`

const basic = (theme: CustomTheme) => css`
  border-color: ${theme.grayLighter};

  &:hover {
    border-color: ${theme.grayLight};
  }

  &:focus,
  &:active {
    border-color: ${theme.linkColor};
  }
`

const variant = ({ theme, variant }: WithCustomTheme<BaseSelectProps>) => {
  if (!variant) return basic(theme)

  const color = theme[variantColors[variant]]

  return css`
    border-color: ${color};

    &:hover {
      border-color: ${darken(0.025, color)};
    }

    &:focus,
    &:active {
      border-color: ${theme.linkColor};
    }
  `
}

const disabled = ({ theme }: WithCustomTheme<BaseSelectProps>) => css`
  &[disabled],
  fieldset[disabled] & {
    background-color: ${theme.backgroundColor};
    color: ${theme.textLightColor};
    pointer-events: none;
  }
`

const BaseSelect = styled.select<BaseSelectProps>`
  ${control}
  ${unselectable}
  ${variant}
  ${disabled}
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.grayDarker};
  cursor: pointer;
  display: block;
  padding: ${em(8)} ${em(32)} ${em(6)} ${em(15)};
  width: 100%;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 ${({ theme }) => theme.grayDarker};
  }

  &::-ms-expand {
    display: none;
  }

  &[multiple] {
    height: auto;
    padding: 0;
  }
`

const Select = React.forwardRef<HTMLSelectElement, Props>(function Select(
  {
    autoComplete = 'off',
    className,
    delay,
    isDisabled,
    isLoading = false,
    isMultiple,
    isRequired,
    variant,
    ...props
  },
  ref?: React.Ref<HTMLSelectElement>,
) {
  const isLoaderVisible = useTimer(isLoading, delay)
  const ariaLabel = isLoading ? { 'aria-label': 'loading' } : {}

  return (
    <Root
      className={className}
      isDisabled={isDisabled}
      isLoading={isLoaderVisible}
      isMultiple={isMultiple}
      variant={variant}
    >
      <BaseSelect
        autoComplete={autoComplete}
        disabled={isDisabled}
        multiple={isMultiple}
        ref={ref}
        required={isRequired}
        variant={variant}
        {...props}
        {...ariaLabel}
      />
    </Root>
  )
})
Select.displayName = 'Select'

export default Select
