import React, { forwardRef } from 'react'

type Dispatch = (event: Event) => void

type Event = { type: 'SET_CONTROLID'; controlId: string }

interface BaseState {
  controlId: string
}

interface State extends BaseState {
  isDisabled: boolean
  isRequired: boolean
  name: string
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isDisabled?: boolean
  isRequired?: boolean
  name: string
}

const DispatchContext = React.createContext<Dispatch | undefined>(undefined)
const StateContext = React.createContext<State | undefined>(undefined)

const reducer = (state: BaseState, { type, controlId }: Event) => {
  switch (type) {
    case 'SET_CONTROLID':
      return { ...state, controlId }
    default:
      throw new Error(`Unhandled event type: ${type as string}`)
  }
}

const Field = forwardRef<HTMLDivElement, Props>(function Field(
  { isDisabled = false, isRequired = false, name, ...props },
  ref,
) {
  const [baseState, dispatch] = React.useReducer<
    React.Reducer<BaseState, Event>
  >(reducer, { controlId: '' })

  const state = React.useMemo(
    () => ({
      ...baseState,
      isDisabled,
      isRequired,
      name,
    }),
    [baseState, isDisabled, isRequired, name],
  )

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <div ref={ref} {...props} />
      </StateContext.Provider>
    </DispatchContext.Provider>
  )
})
Field.displayName = 'Field'

export const useFieldContext = (): [State, Dispatch] => {
  const dispatch = React.useContext(DispatchContext)
  const state = React.useContext(StateContext)

  if (!state || !dispatch) {
    throw new Error('useFieldContext must be used within a Field')
  }

  return [state, dispatch]
}

export default Field
