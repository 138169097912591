import { useMutation } from 'react-query'

import { client } from '~utils/api-client'

const completeEmailVerification = (token: string): Promise<void> => {
  return client(`v1/email-verifications/${token}`, { method: 'PUT' })
}

export function useCompleteEmailVerification() {
  return useMutation(completeEmailVerification)
}
