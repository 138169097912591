import { em } from 'polished'
import React from 'react'

import { styled } from '~styles'

export type Props = {
  email: string
  phone: string
}

const ContactText = styled.div`
  font-size: ${em(14)};
  margin-bottom: 8px;
`

const ContactLink = styled.a`
  color: inherit;
  font-size: ${em(24)};
  font-weight: 600;
`
const EmailLink = styled.a`
  color: inherit;
  font-size: 14px;
  font-weight: 500;
`

const Footer: React.FC<Props> = ({ email, phone, ...props }) => {
  return (
    <div {...props}>
      <ContactText>
        Feel free to contact us for any questions you may have.
      </ContactText>
      <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
      <br />
      <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>
    </div>
  )
}

export default Footer
