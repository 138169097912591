import { rem } from 'polished'
import React from 'react'

import { loadConfig } from '~config.client'
import { mq, styled } from '~styles'

const Root = styled.div`
  align-items: center;
  display: none;
  flex: 1;
  font-size: ${rem(12)};
  justify-content: flex-end;
  padding: 0 10px;

  ${mq('md')} {
    display: flex;
  }
`

const Divider = styled.span`
  background-color: ${({ theme }) => theme.grayLightest};
  display: none;
  height: ${rem(15)};
  width: 1px;
  margin: 0 5px;
  ${mq('lg')} {
    display: inline-block;
  }
`

const CallNow = styled.span`
  display: none;
  ${mq('lg')} {
    display: inline-block;
  }
`

const Contact = () => {
  const { contactPhone } = loadConfig()
  return (
    <Root>
      <span>{contactPhone}</span>
      <Divider />
      <CallNow>
        <a css={{ fontWeight: 600 }} href={`tel:${contactPhone}`}>
          Call Now
        </a>
      </CallNow>
    </Root>
  )
}

export default Contact
