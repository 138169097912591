import React from 'react'
import { FieldError, get, useFormContext } from 'react-hook-form'

import { styled } from '~styles'
import { fieldHelp } from '~styles/mixins'
import { useFieldContext } from './field'

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

const Root = styled.div`
  ${({ theme }) => fieldHelp(theme.danger)};
`

const Help: React.FC<Props> = props => {
  const {
    formState: { errors },
  } = useFormContext()
  const [{ name }] = useFieldContext()

  const error = get(errors, name) as FieldError | undefined

  return error ? (
    <Root role="alert" {...props}>
      {error.message ?? 'Input is required'}
    </Root>
  ) : null // eslint-disable-line unicorn/no-null
}

export default Help
