import { darken, em, transparentize } from 'polished'
import React from 'react'

import {
  NavMenu as BaseNavMenu,
  NavMenuFooter as BaseNavMenuFooter,
} from '~components/nav-menu'
import BaseToggler from '~components/toggler'
import { loadConfig } from '~config.client'
import { useAuth } from '~context/auth-context'
import { mq, styled } from '~styles'
import { hideMediumUp, showMediumUp } from '~styles/mixins'
import BaseAccountMenu from './account-menu'
import BrandLink from './brand-link'
import Contact from './contact'
import UnauthenticatedHeader from './unauthenticated-header'

const Root = styled.header`
  border-bottom: solid 1px ${({ theme }) => theme.whiteTer};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  position: relative;

  ${mq('md')} {
    align-items: center;
    flex-direction: row;
    padding: 5px 0;
  }
`

const Toggler = styled(BaseToggler)`
  color: ${({ theme }) => theme.primary};
`

const AccountMenu = styled(BaseAccountMenu)`
  ${mq('md')} {
    width: 300px;
  }
`

const Menu = styled.div`
  background-color: ${({ theme }) => theme.primary};
  box-shadow: 0 4px 7px 0 ${({ theme }) => transparentize(0.85, theme.black)};
  left: 0;
  right: 0;
  position: absolute;
  top: 100%;

  ${mq('md')} {
    display: none;
  }
`

const NavMenu = styled(BaseNavMenu)`
  font-size: ${em(14)};
  padding: 15px 0;
`

const NavMenuFooter = styled(BaseNavMenuFooter)`
  background-color: rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.primaryContrast};
  padding: 15px;
`

const Divider = styled.span`
  background-color: ${({ theme }) => theme.grayLightest};
  height: ${em(30)};
  width: 1px;
  margin: 0 5px;
  ${showMediumUp}
`

const Header: React.FC = props => {
  const { claims, logout } = useAuth()
  const displayName = claims
    ? `${claims.FirstName} ${claims.LastName}`
    : undefined

  const [isExpanded, setExpanded] = React.useState(false)

  if (!claims) {
    return <UnauthenticatedHeader {...props} />
  }

  const { contactEmail, contactPhone } = loadConfig()

  return (
    <Root {...props}>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <div css={{ flex: 'none' }}>
          <BrandLink theme={loadConfig().siteTheme} showPoweredBy />
        </div>
        <Contact />
        <div css={[hideMediumUp, { flex: 'none' }]}>
          <Toggler
            isActive={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
          />
        </div>
      </div>
      <Divider />
      <AccountMenu displayName={displayName} onSignOut={logout} />
      {isExpanded && (
        <Menu css={{ zIndex: 10 }}>
          <NavMenu variant="dark" />
          <NavMenuFooter email={contactEmail} phone={contactPhone} />
        </Menu>
      )}
    </Root>
  )
}

export default Header
