import React from 'react'

import Footer from '~components/footer'
import Header from '~components/header'
import { loadConfig } from '~config.client'

const Basic: React.FC = ({ children }) => (
  <>
    <Header css={{ marginBottom: 40 }} />
    <main>{children}</main>
    <Footer css={{ marginTop: 10 }} theme={loadConfig().siteTheme} />
  </>
)

export default Basic
