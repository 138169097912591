/* eslint-disable @typescript-eslint/no-unsafe-return */
import { DependencyList, useCallback, useRef } from 'react'

import { useSafeLayoutEffect } from './use-safe-layout-effect'

function useCallbackRef<T extends (...args: any[]) => any>(
  callbackFn?: T,
  deps: DependencyList = [],
): T {
  const ref = useRef(callbackFn)

  useSafeLayoutEffect(() => {
    ref.current = callbackFn
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args) => ref.current?.(...args)) as T, deps)
}

export { useCallbackRef }
