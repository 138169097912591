import { em } from 'polished'
import { motion } from 'framer-motion'
import React, { forwardRef } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import zxcvbn from 'zxcvbn'

import { css, styled } from '~styles'
import { isNil } from '~utils/functional'
import { Score, ScoreVariant, scores } from './types'

type Variant = {
  background: string
  x: string
  transition?: Record<string, any>
}

export interface Props {
  password?: string
}

const BarWrapper = styled.div`
  background-color: ${({ theme }) => theme.grayLightest};
  height: 5px;
  overflow: hidden;
  position: relative;
`

const coverAll = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Bar = styled(motion.div)`
  ${coverAll}
  border-radius: ${({ theme }) => theme.borderRadiusRound};
`

const Help = styled.div`
  display: flex;
  margin-top: 5px;
`

const Warning = styled.span`
  align-items: center;
  color: ${({ theme }) => theme.danger};
  display: flex;
  font-size: ${em(12)};
  font-weight: 600;
`

const ScoreWord = styled.span`
  color: ${({ theme }) => theme.textLightColor};
  font-size: ${em(14)};
  font-weight: 700;
`

const HintItem = styled.li`
  font-size: ${em(12)};
`

interface PasswordScore {
  score?: Score
  suggestions: string[]
  warning?: string
}

const variants: Record<ScoreVariant, Variant> & { hidden: Variant } = {
  hidden: {
    background: '#000',
    x: '-100%',
    transition: { duration: 0.1 },
  },
  weak: {
    background: '#ff3860',
    x: '-90%',
  },
  okay: {
    background: '#ffc466',
    x: '-70%',
  },
  good: {
    background: '#1f9ded',
    x: '-50%',
  },
  strong: {
    background: '#49caa2',
    x: '-20%',
  },
  stronger: {
    background: '#24d160',
    x: '0%',
    transition: { duration: 0.1 },
  },
}

const usePasswordStrength = (value?: string): PasswordScore => {
  if (!value || value.length < 2) return { suggestions: [] }

  const {
    score,
    feedback: { suggestions, warning },
  } = zxcvbn(value)

  return { score, suggestions, warning: warning !== '' ? warning : undefined }
}

const PasswordStrength = forwardRef<HTMLDivElement, Props>(
  function PasswordStrength({ password, ...props }, ref) {
    const { score, suggestions, warning } = usePasswordStrength(password)
    const variant: ScoreVariant | undefined = !isNil(score)
      ? scores[score as Score]
      : undefined

    return (
      <div ref={ref} {...props}>
        <BarWrapper>
          <Bar
            animate={variant ?? 'hidden'}
            initial="hidden"
            transition={{
              type: 'spring',
              damping: 10,
              mass: 0.75,
              stiffness: 200,
            }}
            variants={variants}
          />
        </BarWrapper>
        <Help>
          <div css={{ flex: 1 }}>
            {warning && (
              <Warning>
                <FiAlertTriangle css={{ marginRight: 5 }} /> {warning}
              </Warning>
            )}
            <ul css={{ listStyle: 'disc inside' }}>
              {suggestions.map(suggestion => (
                <HintItem key={suggestion}>{suggestion}</HintItem>
              ))}
            </ul>
          </div>
          <ScoreWord>{variant}</ScoreWord>
        </Help>
      </div>
    )
  },
)
PasswordStrength.displayName = 'PasswordStrength'

export default PasswordStrength
