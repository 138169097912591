import React, { forwardRef } from 'react'
import { ValidationRule, get, useFormContext } from 'react-hook-form'
import { useUIDSeed } from 'react-uid'

import BaseSelect, { Props as BaseSelectProps } from '~components/select'
import { mergeRefs } from '~utils/react'
import { useFieldContext } from './field'

export interface SelectProps
  extends Omit<BaseSelectProps, 'isDisabled' | 'isRequired' | 'name'> {
  rules?: ValidationRule[]
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
  { id, rules, variant, ...props },
  ref,
) {
  const [{ controlId, isDisabled, isRequired, name }, dispatch] =
    useFieldContext()
  const {
    formState: { errors },
    register,
  } = useFormContext()
  const seed = useUIDSeed()

  React.useEffect(
    () =>
      dispatch({
        type: 'SET_CONTROLID',
        controlId: id || seed(name),
      }),
    [dispatch, id, name, seed],
  )

  const isInvalid = Boolean(get(errors, name))

  const { ref: inputRef, ...rest } = register(name, {
    required: isRequired && 'input is required',
    ...rules,
  })

  return (
    <BaseSelect
      aria-invalid={isInvalid ? 'true' : 'false'}
      id={controlId}
      isDisabled={isDisabled}
      isRequired={isRequired}
      ref={mergeRefs(ref, inputRef)}
      variant={isInvalid ? 'danger' : variant}
      {...props}
      {...rest}
    />
  )
})
Select.displayName = 'Select'

export default Select
