export const scores = {
  0: 'weak',
  1: 'okay',
  2: 'good',
  3: 'strong',
  4: 'stronger',
} as const

type ScoreMap = typeof scores

export type Score = keyof ScoreMap

export type ScoreVariant = ScoreMap[Score]
