/* eslint-disable unicorn/no-null */
import { ChangeEvent, useCallback, useRef, useState } from 'react'

import { useCallbackRef } from '~hooks/use-callback-ref'
import { useSafeLayoutEffect } from '~hooks/use-safe-layout-effect'
import { visuallyHidden } from '~styles/mixins'
import { dataAttr } from '~utils/dom'
import { PropGetter } from '~types'
import { mergeRefs } from '~utils/react'
import { useControllableProp } from '~hooks/use-controllable-prop'

export interface UseCheckboxProps {
  defaultChecked?: boolean
  id?: string
  isChecked?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  isReadOnly?: boolean
  isRequired?: boolean
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  tabIndex?: number
  value?: string | number
  'aria-labelledby'?: string
  'aria-invalid'?: true | undefined
  'aria-describedby'?: string
  'aria-label'?: string
}

function useCheckbox(props: UseCheckboxProps = {}) {
  const {
    defaultChecked,
    id,
    isChecked: checkedProp,
    isDisabled,
    isInvalid,
    isReadOnly,
    isRequired,
    name,
    onChange: onChangeProp,
    tabIndex,
    value,
    'aria-describedby': ariaDescribedBy,
    'aria-invalid': ariaInvalid,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    ...htmlProps
  } = props

  const onChange = useCallbackRef(onChangeProp)

  const inputRef = useRef<HTMLInputElement>(null)

  const [checkedState, setCheckedState] = useState(Boolean(defaultChecked))

  const [isControlled, isChecked] = useControllableProp(
    checkedProp,
    checkedState,
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly || isDisabled) {
        return event.preventDefault()
      }

      if (!isControlled) {
        setCheckedState(event.target.checked)
      }

      onChange?.(event)
    },
    [isReadOnly, isDisabled, isControlled, onChange],
  )

  useSafeLayoutEffect(() => {
    if (!inputRef.current) {
      return
    }

    const isSynced = inputRef.current.checked === isChecked

    if (!isSynced) {
      setCheckedState(inputRef.current.checked)
    }
  }, [inputRef.current])

  const getCheckboxProps: PropGetter = useCallback(
    (props = {}, forwardedRef = null) => ({
      ...props,
      ref: forwardedRef,
      'aria-hidden': true,
      'data-checked': dataAttr(isChecked),
      'data-disabled': dataAttr(isDisabled),
      'data-invalid': dataAttr(isInvalid),
      'data-readonly': dataAttr(isReadOnly),
    }),
    [isChecked, isDisabled, isInvalid, isReadOnly],
  )

  const getRootProps: PropGetter = useCallback(
    (props = {}, forwardedRef = null) => ({
      ...htmlProps,
      ...props,
      ref: forwardedRef,
      'data-checked': dataAttr(isChecked),
      'data-disabled': dataAttr(isDisabled),
      'data-invalid': dataAttr(isInvalid),
    }),
    [htmlProps, isDisabled, isChecked, isInvalid],
  )

  const getInputProps: PropGetter = useCallback(
    (props = {}, forwardedRef = null) => {
      return {
        ...props,
        checked: isChecked,
        disabled: isDisabled,
        id,
        name,
        onChange: handleChange,
        readOnly: isReadOnly,
        ref: mergeRefs(inputRef, forwardedRef),
        required: isRequired,
        css: visuallyHidden,
        tabIndex,
        type: 'checkbox',
        value,
        'aria-describedby': ariaDescribedBy,
        'aria-disabled': isDisabled,
        'aria-invalid': ariaInvalid ? Boolean(ariaInvalid) : isInvalid,
        'aria-label': ariaLabel,
        'aria-labelledby': ariaLabelledBy,
      }
    },
    [
      isChecked,
      isDisabled,
      id,
      name,
      handleChange,
      isReadOnly,
      isRequired,
      tabIndex,
      value,
      ariaDescribedBy,
      ariaInvalid,
      isInvalid,
      ariaLabel,
      ariaLabelledBy,
    ],
  )

  const getLabelProps: PropGetter = useCallback(
    (props = {}, forwardedRef = null) => ({
      ...props,
      ref: forwardedRef,
      'data-checked': dataAttr(isChecked),
      'data-disabled': dataAttr(isDisabled),
      'data-invalid': dataAttr(isInvalid),
    }),
    [isChecked, isDisabled, isInvalid],
  )

  return {
    getCheckboxProps,
    getInputProps,
    getLabelProps,
    getRootProps,
    state: {
      isInvalid,
      isChecked,
      isDisabled,
      isReadOnly,
      isRequired,
    },
  } as const
}

export { useCheckbox }
