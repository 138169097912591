import React, { forwardRef } from 'react'

import {
  Button as ButtonBase,
  ButtonProps as ButtonPropsBase,
} from '~components/ui/button'
import { SpinnerIcon } from '~components/icons'
import type { Variant } from '~styles'

export interface Props
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'> {
  isDisabled?: boolean
  isLoading?: boolean
  variant?: Variant
}

const toBaseIntent = (variant?: Variant): ButtonPropsBase['intent'] => {
  switch (variant) {
    case 'primary':
      return 'primary'
    case 'secondary':
      return 'subtle'
    case 'tertiary':
      return 'primary'
    default:
      return 'subtle'
  }
}

const toBaseProps = ({
  isDisabled,
  isLoading,
  variant,
  ...props
}: Props): ButtonPropsBase => ({
  'aria-label': isLoading ? 'loading' : undefined,
  disabled: isDisabled,
  intent: toBaseIntent(variant),
  ...props,
})

const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  { children, isLoading, ...props },
  ref,
) {
  return (
    <ButtonBase ref={ref} {...toBaseProps(props)}>
      {isLoading && (
        <SpinnerIcon className="mr-2 h-4 w-4 animate-spin text-current" />
      )}
      {children}
    </ButtonBase>
  )
})

export default Button

export * from './link-button'
