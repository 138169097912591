import { em } from 'polished'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'

import Button from '~components/button'

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => (
  <div css={{ padding: em(20) }} role="alert">
    <p>Something went wrong. Please try refreshing the app.</p>
    <pre>{error?.message}</pre>
    <Button onClick={resetErrorBoundary}>Try again</Button>
  </div>
)

export default ErrorFallback
