import React, { forwardRef } from 'react'
import { get, useFormContext } from 'react-hook-form'

import {
  Radio as BaseRadio,
  RadioProps as BaseRadioProps,
} from '~components/inputs/radio'
import { mergeRefs } from '~utils/react'
import { useFieldContext } from './field'

export type RadioProps = Omit<
  BaseRadioProps,
  'isDisabled' | 'isRequired' | 'name'
>

const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
  { id, variant, ...props },
  ref,
) {
  const [{ isDisabled, isRequired, name }] = useFieldContext()
  const {
    formState: { errors },
    register,
  } = useFormContext()

  const isInvalid = Boolean(get(errors, name))

  const { ref: inputRef, ...rest } = register(name, {
    required: isRequired && 'Input is required',
  })

  return (
    <BaseRadio
      aria-invalid={isInvalid ? 'true' : 'false'}
      color={isInvalid ? 'danger' : 'default'}
      id={id}
      isDisabled={isDisabled}
      isRequired={isRequired}
      ref={mergeRefs(ref, inputRef)}
      {...props}
      {...rest}
    />
  )
})
Radio.displayName = 'Radio'

export default Radio
