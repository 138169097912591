import { useEffect } from 'react'

import { useTelemetry } from '~context/telemetry-context'

export const useTrackPageView = (name: string) => {
  const { logPageView } = useTelemetry()

  useEffect(() => {
    logPageView(name, location.pathname)
  }, [logPageView, name])
}
