/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { format, parse, parseISO } from 'date-fns'
import IMask, { PIPE_TYPE, createPipe } from 'imask'
import { ChangeEvent } from 'react'

export interface MaskerOptions {
  masked: IMask.AnyMaskedOptions
  transform?: (maskedValue: string) => any
  maskDefault?: (defaultValue: any) => string
}

// https://stackoverflow.com/a/10452789/8786986
export const masker = ({ masked, transform, maskDefault }: MaskerOptions) =>
  (function () {
    const mask = createPipe(masked, PIPE_TYPE.UNMASKED, PIPE_TYPE.MASKED)

    const unmask = createPipe(masked, PIPE_TYPE.MASKED, PIPE_TYPE.UNMASKED)

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const unmasked = unmask(e.target.value)
      const newValue = mask(unmasked)
      // eslint-disable-next-line no-param-reassign
      e.target.value = newValue
    }

    return {
      mask,
      maskDefault: maskDefault ?? mask,
      onChange,
      transform: transform ?? unmask,
      unmask,
    }
  })()

export const currencyMask = masker({
  masked: {
    mask: '$ num{.}cents',
    blocks: {
      num: {
        mask: Number,
        radix: '.',
        scale: 0,
        signed: true,
        thousandsSeparator: ',',
      },
      cents: {
        mask: '00',
        normalizeZeros: true,
        padFractionalZeros: true,
      },
    },
  },
  transform: value => Number(currencyMask.unmask(value)),
  maskDefault: (value: number) => currencyMask.mask(value.toFixed(2)) as string,
})

export const dateFormatClient = 'MM/dd/yyyy'

export const dateMask = masker({
  masked: {
    mask: Date,
    pattern: dateFormatClient.replaceAll('/', '{/}'),
    blocks: {
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
      } as any,
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      } as any,
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
      } as any,
    },
    format: date => format(date, dateFormatClient),
    parse: dateStr => parse(dateStr, dateFormatClient, new Date()),
  },
  transform: String,
  maskDefault: value => format(parseISO(value), dateFormatClient),
})

export const socialSecurityMask = masker({
  masked: {
    mask: '000{-}00{-}0000',
  },
  transform: String,
  maskDefault: String,
})

export const phoneMask = masker({
  masked: {
    mask: '000{-}000{-}0000',
  },
  transform: value => value.replaceAll('-', ''),
  maskDefault: String,
})

export const percentageMask = masker({
  masked: {
    mask: [
      { mask: '' },
      {
        mask: 'num%',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            scale: 3,
            min: 2,
            max: 100,
            radix: '.',
            mapToRadix: [','],
          },
        },
      },
    ],
  },
  transform: value => value.replaceAll('%', ''),
  maskDefault: value => `${value as string}%`,
})

export const mask = {
  currency: currencyMask,
  date: dateMask,
  phone: phoneMask,
  percentage: percentageMask,
  socialSecurity: socialSecurityMask,
}
