import styled from '@emotion/styled'
import { rem } from 'polished'
import { ReactNode, forwardRef } from 'react'

import { CheckboxIcon } from './checkbox-icon'
import { UseCheckboxProps, useCheckbox } from './use-checkbox'

export interface CheckboxProps extends UseCheckboxProps {
  children?: ReactNode
  className?: string
}

const Root = styled.label`
  cursor: pointer;
  display: inline-flex;
  position: relative;
  vertical-align: top;

  &[data-disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }
`

const Control = styled.span`
  --color-active: ${({ theme }) => theme.primary};
  --color-invalid: ${({ theme }) => theme.danger};
  --size: ${rem(20)};

  align-items: center;
  border: 1px solid ${({ theme }) => theme.gray};
  color: ${({ theme }) => theme.white};
  display: inline-flex;
  flex-shrink: 0;
  height: var(--size);
  justify-content: center;
  transition: box-shadow 200ms;
  vertical-align: top;
  width: var(--size);

  &[data-checked] {
    background-color: var(--color-active);
    border-color: var(--color-active);
  }

  &[data-invalid] {
    border-color: var(--color-invalid);
  }
`

const Label = styled.span`
  margin-left: ${rem(12)};
  user-select: none;
`

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { children, className, ...rest } = props

  const {
    getCheckboxProps,
    getInputProps,
    getLabelProps,
    getRootProps,
    state,
  } = useCheckbox(rest)

  return (
    <Root className={className} data-testid="checkbox" {...getRootProps()}>
      <input {...getInputProps({}, ref)} />
      <Control {...getCheckboxProps()}>
        <CheckboxIcon isChecked={state.isChecked} />
      </Control>
      {children && <Label {...getLabelProps()}>{children}</Label>}
    </Root>
  )
})
Checkbox.displayName = 'Checkbox'

export { Checkbox }
