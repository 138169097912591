import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import React from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { cx } from '~utils/classname'

export const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md border border-transparent text-sm font-medium focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 disabled:opacity-50',
  {
    variants: {
      intent: {
        primary: 'bg-primary text-white hover:bg-primary',
        subtle: 'bg-gray-100 text-gray-900 hover:bg-gray-200',
        link: 'bg-transparent text-gray-900 hover:underline hover:bg-transparent',
      },
      size: {
        md: 'h-11 px-4',
        lg: 'h-12 px-4',
      },
    },
    defaultVariants: {
      intent: 'primary',
      size: 'md',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, intent, size, ...props }, ref) => {
    return (
      <button
        className={cx(buttonVariants({ intent, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export interface ButtonLinkProps
  extends LinkProps,
    VariantProps<typeof buttonVariants> {}

export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ className, intent, size, ...props }, ref) => {
    return (
      <Link
        className={cx(buttonVariants({ intent, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
ButtonLink.displayName = 'ButtonLink'
