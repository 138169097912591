import { useEffect } from 'react'

import { useCompleteEmailVerification } from '~api/email-verifications'
import Layout from '~components/layouts/basic'
import Loading from '~components/loading'
import { ButtonLink } from '~components/ui/button'
import { loadConfig } from '~config.client'
import { styled } from '~styles'
import { card } from '~styles/mixins'

const Card = styled.div`
  ${card}
  border-top: 4px solid ${({ theme }) => theme.primary};
  padding: 40px ${({ theme }) => theme.gap}px 50px;
  margin: auto;
  min-height: unset;
  max-width: 600px;
`

export default function Verify() {
  const { mutate, status } = useCompleteEmailVerification()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    mutate(searchParams.get('ott') ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate])

  return (
    <Layout>
      <Card>
        {status === 'loading' && <Loading />}
        {status === 'error' && (
          <div>
            <div className="text-center">
              <h2 className="text-3xl font-bold text-gray-900">
                Verification Link Expired
              </h2>
              <div className="mt-6 text-gray-500">
                Unable to verify account. Please try again or contact support at{' '}
                <span className="whitespace-nowrap">
                  {loadConfig().contactPhone}.
                </span>
              </div>
            </div>
            <div className="mt-10 text-center">
              <ButtonLink to="/">Continue</ButtonLink>
            </div>
          </div>
        )}
        {status === 'success' && (
          <div>
            <div className="text-center">
              <h2 className="text-3xl font-bold text-gray-900">
                Email Verified
              </h2>
              <div className="mt-6 text-gray-500">
                Thank you for verifying your account!
              </div>
            </div>
            <div className="mt-10 text-center">
              <ButtonLink
                to="#"
                onClick={() => {
                  // hard reload workaround for nested layout issues
                  window.location.replace('/')
                }}
              >
                Continue to dashboard
              </ButtonLink>
            </div>
          </div>
        )}
      </Card>
    </Layout>
  )
}
